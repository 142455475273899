<template>
  <div class="input-group">
    <label class="radio">
    <input type="radio" :value="company" :name="name" v-model="picked" :checked="false">
    {{ labelTxt }} </label>
  </div>
</template>

<script>
export default {
name: "RadioButton",
  props: {
    labelTxt: {
      type: String,
      default: "Наименование"
    },
    company: {},
    name: {
    },
    value: {}
  },
  computed: {
    picked: {
      get: function() {
        return this.value
      },
      set: function() {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit("change", this.company)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-group {
  margin: 24px 0;
}
input {
  margin-right: 14px;
}

</style>
