<template>
  <div class="wrap">

  <label class="file-select">
    <p v-if="errorTxt" class="err"> {{ errorTxt }}</p>
    <div class="select-button">
      <div v-if="value" class="see space-between">
        <span>
          Просмотреть файл:
          <span class="filename" v-for="(item, index ) in items" :key="index"> {{item}} </span>
        </span>

      </div>

      <span class="add" v-else>+ Добавить файл</span>
    </div>
    <input type="file" :ref="inputName" @change="handleFileChange"  multiple required accept="image/*,.pdf"
            :name="inputName"/>
  </label>

    <span v-if="value" class="delete" @click="deleteFile">Удалить</span>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  data() {
    return {
      items: []
    }
  },
  props: {
    // Using value here allows us to be v-model compatible.
    value: {},
    inputName: {
      type: String
    },
    errorTxt: {
      type: String,
    },
  },

  methods: {
    handleFileChange(e) {
      let init = this;
      // Whenever the file changes, emit the 'input' event with the file data.
      const arr = e.target.files
      let files = []
      if(arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          this.getBase64(arr[i]).then(data => {
            let d = init.items.slice();
            d[i] = arr[i].name;
            files[i] = data;
            init.items = d;
            this.$emit('input', files)
          })
        }
      }
    },
    deleteFile() {
      this.items = [];
      this.$emit('input', null);
      this.$refs[this.inputName].value = null;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.select-button {
  margin-top: 21px;
  margin-bottom: 55px;
}
.wrap {
  display: flex;
  justify-content: space-between;
}
.err {
  color: #e5221c;
  font-size: 16px;
  line-height: 18px;
}

.delete {
  color: $error;
  cursor: pointer;
  margin-top: 21px;
}

.file-select > .select-button {
  color: $water-blue;

  .add {
    cursor: pointer;
  }

  .see {
    display: flex;
    color: $text-gray;
    .filename {
      color: $water-blue;
      cursor: pointer;
    }

  }
}
@include md-max {
  .select-button {
    margin-top: 10px;
    margin-bottom: 25px;
  }


  .delete {
    margin-top: 10px;
  }
}

/* Don't forget to hide the original file input! */
.file-select > input[type="file"] {
  display: none;
}
</style>
