<template>
<div class="wrapper">
  <div class="col-lg-6">
    <h1>Добавление организации</h1>
  </div>
  <div class="row step_1">
    <div class="col-12">
      <h3>Шаг 1 из 2. Поиск организации по ИНН</h3>

      <form @submit.prevent novalidate>

        <div class="col-lg-6">
          <InputGroup :labelTxt="'ИНН организации'"
                      :inputId="'1'" v-model="inn"  :type="'number'"
                      :errorTxt="errorTextInn || errorTextInn1"
                      :minLength="innLength"
                      :maxlength="innMaxLength"
                      :isDisabled="isFormSent && !errors.length"
                       />


          <InputGroup :labelTxt="'КПП организации'"
                      :inputId="'2'" v-model="kpp" :type="'number'"
                      :errorTxt="errorTextKpp || errorTextKpp1"
                      :maxlength="innMaxLength"
                      :isDisabled="isFormSent && !errors.length" />

        </div>

        <div v-if="companies.length > 0 && !errors.length && isFormSent" class="found_wrap">
          <p class="found">Для ИНН {{ inn }} {{ companies.length | ending(['найдена', 'найдено', 'найдено'])}} {{ companies.length }} {{ companies.length | ending(['организация', 'организации', 'организаций'])}}. <span v-if="companies.length > 1">Выберите Вашу</span></p>

          <RadioButton v-for="(item, index) in companies"
                       :key="index"
                       :name="'options'"
                       :value="selectedValue"
                       :company="item.name"
                       :labelTxt="item.name + ' (ИНН ' + item.inn + ', КПП ' + item.kpp + ')'"
                       @change="changeRadio" />
        </div>

        <div class="err" v-if="!errors.length && !companies.length && isFormSent">

          <p class="error_txt">Не найдено организации</p>
          <p class="err_msg">Пожалуйста, заполните данные вручную</p>
        </div>

        <div class="row text-center text-left-md">
          <div class="col-md-4" v-if="errors.length || !isFormSent">
            <a class="btn btn-bg" @click="sendForm1" :class="{ 'disabled': sending1}">Далее</a>
          </div>
          <div class="col-md-4 ml" v-if="errors.length">
            <a class="btn btn-border">Обратиться в поддержку</a>
          </div>
        </div>
      </form>
    </div>
  </div>


  <div class="row step_2" v-if="isFormSent && !errors.length">
    <h3>Шаг 2 из 2. Проверьте данные об организации</h3>
    <form @submit.prevent novalidate>

    <div class="col-lg-8">
      <InputGroup :labelTxt="'Полное наименование'"
                  :inputId="'3'"
                  :errorTxt="(companyData.fullName == null || companyData.fullName == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  v-model="companyData.fullName" :val="companyData.fullName" />

      <InputGroup :labelTxt="'Краткое наименование'"
                  :errorTxt="(companyData.shortName == null || companyData.shortName == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  v-model="companyData.shortName" :val="companyData.shortName"
                  :inputId="'4'"  />

      <InputGroup :labelTxt="'Адрес регистрации'"
                  :errorTxt="(companyData.address == null || companyData.address == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  v-model="companyData.address" :val="companyData.address"
                  :inputId="'5'"  />
    </div>

    <div class="col-lg-6">
      <InputGroup :labelTxt="'ОГРН'"
                  v-model="companyData.ogrn" :val="companyData.ogrn"
                  :errorTxt="(companyData.ogrn == null || companyData.ogrn == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  :type="'number'" :minLength="9"
                  :inputId="'6'"  />


      <div class="input-group">
        <label for="8"> Дата регистрации </label>
        <datepicker id="8" v-model="companyData.dateReg"
                    :input-class="'inp'"
                    name="uniquename"
                    :format="'dd.MM.yyyy'"
                    @input="formatDate"
        ></datepicker>
        <p v-if="(companyData.dateReg == null || companyData.dateReg == '') && isSecondFormSent ? 'Заполните поле' : ''" class="err"> {{ 'Заполните поле' }}</p>
      </div>

      <p>Скан свидетельства о регистрации
        <FileInput v-model="companyData.scanReg"
                   :errorTxt="(companyData.scanReg == null || companyData.scanReg == '') && isSecondFormSent ? 'Заполните поле' : ''"
                   :inputName="'scanReg'"/>
      </p>

      <p>Скан приказа о назначении директора
        <FileInput v-model="companyData.scanDirectorOrder"
                   :errorTxt="(companyData.scanDirectorOrder == null || companyData.scanDirectorOrder == '') && isSecondFormSent ? 'Заполните поле' : ''"
                   :inputName="'scanDirectorOrder'"/>
      </p>

      <p>Доверенность от директора <a href="#">(скачать образец)</a>
        <FileInput v-model="companyData.scanDirectorProxy"
                   :errorTxt="(companyData.scanDirectorProxy == null || companyData.scanDirectorProxy == '') && isSecondFormSent ? 'Заполните поле' : ''"
                   :inputName="'scanDirectorProxy'"/>
      </p>

      <p>Скан листа записи ЕГРЮЛ
        <FileInput v-model="companyData.numReg"
                   :inputName="'numReg'"/>
      </p>

      <InputGroup :labelTxt="'Наименование ФНС, выдавшей свидетельство'"
                  :errorTxt="(companyData.fns == null || companyData.fns == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  v-model="companyData.fns" :val="companyData.fns"
                  :inputId="'9'" />

<!--      <InputGroup :labelTxt="'Наименование должности руководителя'"-->
<!--                  v-model="companyData.director" :val="companyData.director"-->
<!--                  :inputId="'10'" />-->

      <InputGroup :labelTxt="'ФИО руководителя'"
                  :errorTxt="(companyData.director == null || companyData.director == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  v-model="companyData.director" :val="companyData.director"
                  :inputId="'11'" />

      <InputGroup :labelTxt="'Электронная почта  руководителя'"
                  :errorTxt="(companyData.email == null || companyData.email == '') && isSecondFormSent ? 'Заполните поле' : ''"
                  :type="'email'"
                  v-model="companyData.email" :val="companyData.email"
                  :inputId="'12'" />


      <p class="rules">
        Регистрируясь на этом сайте, вы подтверждаете, что ознакомились и согласны с Правилами:
        <a href="">порядком предоставления услуг, пользовательским соглашением, политикой обработки личных данных, офертой.
        </a>
      </p>

      <div class="check_1">
        <input type="checkbox" id="checkbox" v-model="checked">
        <label for="checkbox">с правилами ознакомлен(а)</label>
      </div>

      <button type="submit" @click="sendForm2($event)" class="btn continue" :class="{ 'btn-bg': checked, 'disabled': !checked || sending2}">Продолжить</button>
      <p class="err" v-if="err">Все поля должны быть заполнены</p>
    </div>

    </form>


  </div>

</div>
</template>

<script>
import InputGroup from "@/components/form/InputGroup";
import RadioButton from "@/components/form/RadioButton";
import FileInput from "@/components/form/FileInput";
import Datepicker from 'vuejs-datepicker';
import router from "@/router";
import moment from 'moment';

export default {
name: "Add",
  data() {
    return {
      inn: '',
      kpp: '',
      innLength: 10,
      innMaxLength: 12,
      isFormSent: false,
      isSecondFormSent: false,
      selectedValue: "",
      errorTextInn1: "",
      errorTextKpp1: "",
      checked: false,
      err: false,
      file: null,
      date: '',
      sending1: false,
      sending2: false,
    }
  },
  components: {FileInput, RadioButton, InputGroup, Datepicker},
  mounted() {
    if(!this.$store.getters.userId) {
      this.$router.push('/')
    }
  },
  computed: {
    companies() {
      return this.$store.getters.companyData;
    },
    companyData() {
      if(this.selectedValue && this.companies.length > 1){
        return this.$store.getters.companyDataByName(this.selectedValue);
      } else if (this.companies.length === 1) {
        return this.companies[0].info
      } else
        return {
          shortName: "",
          fullName: "",
          address: "",
          ogrn: "",
          numReg: "",
          dateReg: "",
          scanReg: "",
          scanDirectorOrder: "",
          scanDirectorProxy: "",
          fns: "",
          director: "",
          email: ""
        };
    },
    errorTextInn() {
        return this.$store.getters.errorTextByName('inn');
    },
    errorTextKpp() {
      return this.$store.getters.errorTextByName('kpp');
    },
    errors() {
      return this.$store.getters.errors;
    }
  },
  methods: {
    async sendForm1() {
      if(this.sending1)
        return;

      let init = this;

      this.errorTextKpp1 = "";
      this.errorTextInn1 = "";

      let check = false;
      this.sending1 = true;

      if(this.inn.length < this.innLength){
        this.errorTextInn1 = "Минимальное количество символов: " + this.innLength;
        check = true;
      }


      if(check) {
        this.sending1 = false;
        return;
      }

      await this.$store.dispatch('sendInn', {inn: this.inn, kpp: this.kpp}).then(() => {
        init.isFormSent = true;
        this.sending1 = false;
      });

      if(init.companies.length)
        init.selectedValue = init.companies[0].name;
    },

    async sendForm2(e) {
      this.err = false;
      if(e.target.classList.contains("disabled"))
        return;

      if(this.sending2)
        return;

      this.isSecondFormSent = true;
      this.sending2 = true;

      Object.entries(this.companyData).map(([key, val]) => {
        if(val != null) {
          if(Array.isArray(val)) {
            if(!val.length) {
              this.err = true;
              return;
            }
          }
          else if(key != "numReg" && val.trim() == ""){
            this.err = true;
            return;
          }
        }
        else {
          this.err = true;
          return;
        }
      });

      if(this.err){
        this.sending2 = false;
        return;
      }

      const company = {
        inn: this.inn,
        kpp: this.kpp,
        name: this.companyData.shortName,
        info: this.companyData
      }

      await this.$store.dispatch('sendFormData', company)
          .then((response) => {
            this.sending2 = false;
            router.push('result');
          }).catch((err) => {
            this.sending2 = false;
            console.log(err);
          });
    },
    changeRadio(val) {
      this.selectedValue = val;

    },
    formatDate() {
      this.companyData.dateReg = moment(this.companyData.dateReg).format('YYYY-MM-DD');
    }
  }
}
</script>

<style lang="scss">

.inp {
  font-size: 18px;
  color: $text-gray;
  padding: 18px 23px;
  border: 1px solid $light-border;
  border-radius: 8px;
}

.inp:focus {
  outline: none;
  color: #000;
}
</style>

<style scoped lang="scss">
h1 {
  margin-top: 0;
  margin-bottom: 60px;
  font-size: 44px;
  line-height: 1.5;
}

h3 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 38px;
}

.step_2 {
  font-size: 18px;
  line-height: 1.56;
  margin-top: 56px;


  .rules {
    color: $text-gray;
    line-height: 1.3;
  }

  .err {
    color: #e5221c;
    margin-top: 10px;
    font-size: 16px;
    line-height: 26px;
  }


  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    label {
      color: $navy;
      margin-bottom: 11px;
    }

    p {
      color: #e5221c;
      margin-top: 10px;
      font-size: 16px;
      line-height: 26px;
    }
  }


  .col-md-6 .input-group:last-of-type {
    margin-bottom: 58px;
  }

  .check_1 {
    margin-top: 19px;

    #checkbox {
      margin-right: 16px;
    }
  }

  .continue {
    margin-top: 53px;
    border: none;
  }

  .btn {
    width: 100%;
    max-width: 370px;
  }

}

.step_1 {
  font-size: 18px;
  line-height: 1.56;

  .found {
    color: $text-gray;
  }

  .found_wrap {
    .input-group:last-of-type {
      margin-bottom: 0px;
    }
  }

  .input-group:last-of-type {
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    max-width: 370px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ml {
    margin-left: 30px;
  }

  .error_txt {
    font-size: 16px;
    font-weight: bold;
    color: $error;
  }
  .err_msg {
    font-size: 16px;
  }
}

@include sm-max {
  h1 {
    font-size: 32px;
    line-height: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    line-height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .input-group, .step_2 .input-group {
    margin-bottom: 15px;
  }
}

@include md-max {
  h1 {
    margin-bottom: 20px;
  }


  .step_1 {
    font-size: 16px;
    .ml {
      margin-left: 0px;
      margin-top: 15px;
    }
  }



  .step_2 {
    font-size: 16px;
    margin-top: 15px;

    .input-group {

      label {
        font-size: 16px;
        margin-bottom: 8px;
      }

      input {
        font-size: 16px;
        line-height: 20px;
        padding: 14px 21px;
      }
    }
  }
}

</style>
